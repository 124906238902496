<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">Consider the reaction shown below:</p>

      <p class="mb-3 pl-14">
        <stemble-latex :content="`$\\ce{2A(aq) + B(aq) <=> C(aq) + D(aq)}$`" />
      </p>

      <p class="mb-4">
        If the concentrations of each of the reagents at equilibrium are
        <stemble-latex content="$\text{[A]=}$" />
        <number-value :value="concA" unit="\text{M,}" />
        <stemble-latex content="$\text{[B]=}$" />
        <number-value :value="concB" unit="\text{M,}" />
        <stemble-latex content="$\text{[C]=}$" />
        <number-value :value="concC" unit="\text{M, and}" />
        <stemble-latex content="$\text{[D]=}$" />
        <number-value :value="concD" unit="\text{M,}" />
        determine the equilibrium constant for this reaction.
      </p>

      <calculation-input
        v-model="inputs.Kc"
        class="mb-3"
        prepend-text="$\text{K:}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';

export default {
  name: 'Question369',
  components: {
    StembleLatex,
    NumberValue,
    CalculationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        Kc: null,
      },
    };
  },
  computed: {
    concA() {
      return this.taskState.getValueBySymbol('concA').content;
    },
    concB() {
      return this.taskState.getValueBySymbol('concB').content;
    },
    concC() {
      return this.taskState.getValueBySymbol('concC').content;
    },
    concD() {
      return this.taskState.getValueBySymbol('concD').content;
    },
  },
};
</script>
